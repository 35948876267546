import React from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    padding: "5% 18% 1% 18%",
    width: "100%",
    marginTop: "100px",
    [theme.breakpoints.down("sm")]: {
      padding: "5% 5% 5% 5%",
      marginTop: "30%",
    },
  },
  infoWrapper: {
    paddingLeft: "40px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      marginTop: "30px",
    },
  },
  sectionTitle: {
    fontFamily: '"Nanum Myeongjo", serif',
    fontSize: "2.5em",
    color: "#484646",
    marginTop: "10px",
  },
  titleLabel: {
    fontFamily: '"Karla", sans-serif',
    textTransform: "uppercase",
    letterSpacing: "0.3em",
    fontSize: "0.7rem",
    color: "#898989",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
  },
  labelDesc: {
    fontSize: "0.8em",
    color: "#615d5d",
  },
  a: {
    textDecoration: "none",
    color: "#615d5d",
    fontSize: "0.85em",
    "&:hover": {
      color: "#3b5280",
      fontWeight: 600,
      transition: "color 1s ease",
    },
  },
}))

const MapInfo = () => {
  const classes = useStyles()

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item sm={7}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.2823491914264!2d126.92196891578865!3d37.54841107980135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c99b370442f13%3A0xabd7132bbe0dd889!2z66ek6rG07ZSE66Gc7KCd7Yq4!5e0!3m2!1sko!2skr!4v1588145127728!5m2!1sko!2skr"
            title="google map"
            width="100%"
            height="450"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            // tabIndex="0"
          ></iframe>
        </Grid>
        <Grid item sm={5} className={classes.infoWrapper}>
          <h4 className={classes.sectionTitle}>Meet Magan</h4>
          <p className={classes.titleLabel}>Address</p>
          <p className={classes.labelDesc}>
            서울시 마포구 상수동 와우산로 10길 29 1층
          </p>
          <p className={classes.titleLabel}>phone</p>
          <p className={classes.labelDesc} style={{ letterSpacing: "0.3em" }}>
            <a href="tel:010-6583-0113" className={classes.a}>
              010.6583.0113{" "}
            </a>
            <br />
          </p>
          <p className={classes.titleLabel}>email</p>
          <p className={classes.labelDesc} style={{ letterSpacing: "0.3em" }}>
            <a href="mailto:hongsg0113@naver.com" className={classes.a}>
              hongsg0113@naver.com
            </a>
          </p>
        </Grid>
      </Grid>
    </>
  )
}

export default MapInfo

import React from "react"
import Layout from "../components/layout"
import MapInfo from "../components/map"
import ContactForm from "../components/contactForm"
import SEO from "../components/seo"
import website from "../../config/website"

const Contact = () => {
  return (
    <Layout>
      <SEO
        title={`오시는 길, 상담 예약하기 | ${website.titleAlt}`}
        desc="매건프로젝트에 상담예약을 의뢰해보세요. 상수동에서 상상 속에만 있던 꿈의 프로젝트를 매건과 함께 만들어 보아요."
      />
      <MapInfo />
      <ContactForm />
    </Layout>
  )
}

export default Contact

import React, { useState } from "react"
import {
  Grid,
  TextField,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core"
import { Formik, Form, Field, useField } from "formik"
import * as yup from "yup"
import { makeStyles } from "@material-ui/core/styles"
import "../styles/component.css"
import { Logo } from "./logo"

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: "0.8em",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  container: {
    display: "flex",
    padding: "5% 18% 1% 18%",
    width: "100%",
    marginTop: "100px",
    [theme.breakpoints.down("sm")]: {
      padding: "5% 5% 5% 2%",
      marginTop: "25%",
    },
  },
  formControl: {},
  formWrapper: {
    width: "100%",
    padding: "25px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  inputForm: {
    marginRight: "30px",
    marginBottom: "10px",
  },
  inputFormMessage: {
    marginBottom: "10px",
    width: "85ch",
  },
  sectionTitle: {
    fontFamily: '"Nanum Myeongjo", serif',
    fontSize: "2.5em",
    color: "#484646",
    marginTop: "10px",
  },
  outlined: {
    borderRadius: 0,
    borderColor: "rgba(0, 0, 0, 0.15)",
    padding: "7px 30px",
    fontSize: "1.15em",
    color: "#615d5d",
    letterSpacing: "0.2em",
    "&:hover": {
      color: "#3b5280",
      transition: "color 1s ease",
    },
  },
  errorText: {
    fontSize: "0.8em",
    color: "#F44337",
  },
  dialogWrapper: {
    textAlign: "center",
  },
  dialogText: {
    fontSize: "0.9em",
  },
}))

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = yup.object({
  name: yup
    .string()
    .required("성함을 적어주세요")
    .min(2, "이름은 두글자 이상입니다")
    .max(20),
  phone: yup
    .string()
    .matches(phoneRegExp, "정확한 번호를 적어주세요")
    .min(9, "정확한 번호를 적어주세요")
    .required("번호를 적어주세요"),
  email: yup
    .string()
    .email("정확한 이메일을 입력해주세요")
    .required("이메일을 적어주세요"),
  eventDate: yup.date().required("예상하는 행사날짜를 선택해주세요"),
  eventType: yup.string().ensure().required("행사종류를 선택해주세요"),
  message: yup.string(),
})

const MyTextField = ({ type, label, autoComplete, ...props }) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ""
  return (
    <TextField
      {...field}
      helperText={errorText}
      label={label}
      fullWidth={true}
      error={!!errorText}
      type={type}
      autoComplete={autoComplete}
    />
  )
}

const MyDateField = ({ type, label, ...props }) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ""
  return (
    <TextField
      {...field}
      helperText={errorText}
      label={label}
      error={!!errorText}
      type={type}
      fullWidth={true}
      InputLabelProps={{
        shrink: true,
      }}
    />
  )
}

const MyMessageField = ({ type, label, handleChange, ...props }) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ""
  return (
    <TextField
      {...field}
      helperText={errorText}
      label={label}
      error={!!errorText}
      type={type}
      multiline={true}
      rows={3}
      rowsMax={8}
      fullWidth={true}
    />
  )
}

const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&")

const ContactForm = () => {
  const classes = useStyles()
  const [dialogOpen, setDialogOpen] = useState(false)

  const dialogClose = () => {
    setDialogOpen(false)
  }

  return (
    <>
      <Formik
        initialValues={{
          "bot-field": "",
          "form-name": "magan-contact",
          name: "",
          phone: "",
          email: "",
          eventType: "",
          eventDate: "",
          message: "",
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true)
          try {
            fetch("/", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: encode({
                "form-name": "magan-contact",
                ...values,
              }),
            }).then(() => {
              setDialogOpen(true)
              // console.log("success")
              setSubmitting(false)
              resetForm()
            })
          } catch (event) {
            console.error(event.message)
          }
          // console.log("submit: ", values)
        }}
        validationSchema={validationSchema}
      >
        {({ values, errors, isSubmitting, handleChange, touched }) => (
          <>
            <Form
              className={classes.root}
              data-netlify={true}
              data-netlify-honeypot="bot-field"
              method="post"
              name="magan-contact"
            >
              <Field type="hidden" name="bot-field" />
              <Field type="hidden" name="form-name" />
              <Grid container className={classes.container}>
                <Grid item sm={12} className={classes.formWrapper}>
                  <h4 className={classes.sectionTitle}>Contact Us</h4>
                </Grid>
                <Grid item sm={6} xs={12} className={classes.formWrapper}>
                  <FormControl fullWidth={true}>
                    <InputLabel id="select-label">행사종류</InputLabel>
                    <Select
                      labelId="select-id"
                      id="simple-select"
                      name="eventType"
                      value={values.eventType}
                      onChange={handleChange}
                    >
                      <MenuItem value={"웨딩"}>웨딩</MenuItem>
                      <MenuItem value={"돌잔치"}>돌잔치</MenuItem>
                      <MenuItem value={"기업행사"}>기업행사</MenuItem>
                      <MenuItem value={"플라워디스플레이"}>
                        플라워 디스플레이
                      </MenuItem>
                      <MenuItem value={"기타"}>기타</MenuItem>
                    </Select>
                    {errors.eventType && touched.eventType && (
                      <div>
                        <p className={classes.errorText}>{errors.eventType}</p>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12} className={classes.formWrapper}>
                  <MyDateField
                    id="date"
                    label="예상 행사일"
                    type="date"
                    value={values.eventDate}
                    name="eventDate"
                    //   defaultValue="2020-04-30"
                  />
                </Grid>
                <Grid item sm={4} xs={12} className={classes.formWrapper}>
                  <MyTextField
                    name="name"
                    type="input"
                    value={values.name}
                    label="성함"
                  />
                </Grid>
                <Grid item sm={4} xs={12} className={classes.formWrapper}>
                  <MyTextField
                    name="phone"
                    type="input"
                    autoComplete="tel"
                    value={values.phone}
                    label="연락처"
                  />
                </Grid>
                <Grid item sm={4} xs={12} className={classes.formWrapper}>
                  <MyTextField
                    name="email"
                    type="input"
                    autoComplete="email"
                    label="이메일"
                    value={values.email}
                  />
                </Grid>
                <Grid item sm={12} className={classes.formWrapper}>
                  <MyMessageField
                    label="문의 메시지"
                    name="message"
                    type="input"
                    value={values.message}
                  />
                </Grid>
                <Grid item sm={12} className={classes.formWrapper}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="outlined"
                    className={classes.outlined}
                  >
                    제출하기
                  </Button>
                </Grid>
                {/* <Grid item sm={12}>
                  <pre>{JSON.stringify(values, null, 2)}</pre>
                  <pre>{JSON.stringify(errors, null, 2)}</pre>
                </Grid> */}
              </Grid>
            </Form>
            <Dialog
              open={dialogOpen}
              keepMounted
              onClose={dialogClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              className={classes.dialogWrapper}
            >
              <Logo width={"100px"} />
              <DialogTitle id="alert-dialog-slide-title">
                {"메시지 전송 성공"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-slide-description"
                  className={classes.dialogText}
                >
                  감사합니다. <br />
                  확인 후 빠른 연락 드리겠습니다.
                </DialogContentText>
                <DialogActions style={{ justifyContent: "center" }}>
                  <Button
                    onClick={dialogClose}
                    className={classes.outlined}
                    variant="outlined"
                  >
                    확인
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
          </>
        )}
      </Formik>
    </>
  )
}

export default ContactForm
